<template>
  <div>
    {{order}}
  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import {ElementScalePropTableColumn} from "@/mixins/elementScalePropTableColumn";
import {mapGetters} from "vuex";

export default {
  name: 'element-order-table-column',
  components: {ClickToEdit},
  mixins: [ElementScalePropTableColumn],

  props: {},

  computed: {

    ...mapGetters('card/sections', {
      sections: 'sections',
      sectionsElements: 'sectionsElements'
    }),
    order() {
      const thisSectionType = this.scope._self.section.type;
      if( thisSectionType === 'basic' ){
        return this.scope.$index+1;
      }

      const basicSectionCount = this.sectionsElements[this.sections.find(s => s.type === 'basic').id]?.length;
      if( thisSectionType === 'personal_library' ){
        return (basicSectionCount+1) + '.' + (+this.scope.$index+1);
      }

      if( thisSectionType === 'personal_manual' ){
        const personalLibrarySectionCount = this.sectionsElements[this.sections.find(s => s.type === 'personal_library').id]?.length;
        return (basicSectionCount+1) + '.' + (personalLibrarySectionCount +this.scope.$index + 1);
      }

      if( thisSectionType === 'quality' ){
        return (basicSectionCount+2) + '.' + (+this.scope.$index + 1);
      }
      return 999;
    },
  },
  data() {
    return {

    }
  },

  methods: {

  }
}

</script>

<style>

</style>
